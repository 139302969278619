import { hasCharts, charts } from '@cfed/indicators-and-data/context';
import {
    isStructuredDataEnabled,
    hasOverride,
    overrideDataset,
} from './helpers/features';
import { accordionAdapter } from './helpers/accordion-adapter';
import { initChartComponents } from './services/chart-loader-service';

export async function initSiteCharts() {
    await initChartComponents([
        'chart-router',
        'chart-nowcasting',
        'chart-highcharts',
    ]);

    if (!hasCharts()) return;

    accordionAdapter(charts);

    if (isStructuredDataEnabled()) {
        const chartMetaService = (await import('./services/meta-service'))
            .default;

        let dataset = chartMetaService.createDataSet();
        if (hasOverride) dataset = overrideDataset(dataset);
        const scriptEl = chartMetaService.createScriptElement(dataset);

        document.head.appendChild(scriptEl);
    }
}
