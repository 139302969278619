import jQuery from 'jquery';
import select2 from 'select2';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import initPrivacy from '@cfed/privacy';
import initGroups from '@cfed/groups';
import initNavigation from '@cfed/navigation';
import initGeneralContent from '@cfed/general-content';
import initIcons from '@cfed/theming/js/iconography';
import { initSearch } from './search';
import initCareers from './careers';
import { initSiteCharts } from './charts';
import { isInExperienceEditor } from '@cfed/theming/js/helpers';

import '../scss/clevelandfed.scss';

globalThis.jQuery = jQuery;
select2(document, jQuery);

const iconLoader = async () => (await import('./icons')).default;

initNavigation();
initPrivacy();
initGroups();
initGeneralContent('clevelandfed');

if (!isInExperienceEditor()) initSearch();
initIcons(iconLoader);
initCareers();
initSiteCharts();

if (globalThis?.SitecoreSettings) {
    import('@cfed/theming/js/EnvironmentContextBanner');
}
