export async function initSearch() {
    await Promise.all([
        import('@cfed/site-search/search-facets'),
        import('@cfed/site-search/search-box'),
        import('@cfed/site-search/search-filters'),
        import('@cfed/site-search/search-taxonomy'),
        import('@cfed/site-search/search-related-topics'),
        import('@cfed/site-search/search-spelling'),
    ]);

    import('./search-results/search-results');
}
