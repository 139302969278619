const modules = import.meta.glob([
    './../**/chart-*.js',
    '!../**/chart-reset-button.js',
    '!./chart-router.js',
    '!./../*.styles.js',
]);

const createId = (key) => key.replace(/^.*?chart-(.*?)\.js$/, 'chart-$1');

export const chartsById = new Map(
    Object.entries(modules).reduce(
        (acc, [key, value]) => [...acc, [createId(key), value]],
        []
    )
);

export function initChartComponents(charts = []) {
    const chartList =
        charts.length > 0 ? charts : Array.from(chartsById.keys());

    return Promise.all(chartList.map((chart) => getChartById(chart)));
}

// console.log(chartsById);

export async function getChartById(id) {
    if (chartsById.has(id)) {
        const { tagName } = await chartsById.get(id)();

        return tagName;
    }

    return Promise.resolve();
}
