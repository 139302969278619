import _memoize from 'lodash/memoize';

export function customImport(src, elements = [document.body]) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;

        elements.map((el) => el.appendChild(script));
    });
}

// Ensure the fusion dependency will only be loaded once regardless of the
// number instances
export const externalImport = _memoize(customImport);

export const isInExperienceEditor = () =>
    JSON.parse(
        import.meta.env.VITE_IS_IN_EXPERIENCE_EDITOR ??
            document.body.classList.contains('on-page-editor')
    );

// https://vitejs.dev/guide/env-and-mode.html#env-variables
export const baseUrl = import.meta.env.BASE_URL.replace(/\/$/, '');

export function getPageOpenGraph() {
    const getProp = (attribute) =>
        document
            .querySelector(`meta[property="og:${attribute}"]`)
            ?.getAttribute('content');

    return {
        title: getProp('title'),
        description: getProp('description'),
        url: getProp('url'),
    };
}

export async function fetchText(url) {
    return (await fetch(url)).text();
}

export async function fetchJSON(url) {
    return (await fetch(url)).json();
}
